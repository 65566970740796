function firstopen(){
      $crisp.push(["set", "session:data", [[["hasFirstOpenSegment", true]]]]);
      $crisp.push([
          "do",
          "message:show",
          ["text", "How can we help with MommeSilk?"],
        ]);
        $crisp.push([
          "do",
          "message:show",
          [
            "picker",
            {
              "id": "call-date1",
              "text": "You can continue to inquire, or please click to select the service you need!",
              "choices": [
                {
                  "value": "1",
                  "icon": "",
                  "label": "Track an order",
                  "selected": false,
                },
                {
                  "value": "2",
                  "icon": "",
                  "label": "Cancel an order",
                  "selected": false,
                },
                {
                  "value": "3",
                  "icon": "",
                  "label": "Return product",
                  "selected": false,
                },
                {
                  "value": "4",
                  "icon": "",
                  "label": "Talk to humans",
                  "selected": false,
                }
              ],
            },
          ],
        ])
  }

  
  $crisp.push(["on",
    "chat:opened",
    function(){
      let sessionData = $crisp.get('session:data');
      if (sessionData && sessionData.hasFirstOpenSegment) {
        
      }else{
        firstopen()
      }
      
    }
  ]);

  $crisp.push(["on", "message:received", function(data) {
     if (data.origin !== "update") {
      return;
    }
  
    // Skip messages types other than pickers
    if (data.type !== "picker") {
      return;
    }
  
    // Skip other ids
    if (data.content.id !== "call-date1") {
      return;
    }
    let _choice = data.content.choices.find(choice => choice.selected)
    $crisp.push(["do","message:send",["text",  _choice.label]])
}])

